import React, { Component } from 'react';
import { Select, Typography, Spin } from 'antd';
import app from '../base';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Text } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export class OndeEntregamos extends Component {
  constructor(props){
    super(props)
    this.state = {
      cidadeSelecionada: null,
      prazoSelecionado: null,
      cidades: [],
      isLoading: true
    }
  }

  componentDidMount(){
    let cidades = []

    app.firestore().collection('cidades').get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        let objeto = {
          id: doc.id,
          cidade: doc.data().cidade,
          prazo: doc.data().prazo
        }
        cidades.push(objeto)
        this.setState({
          cidades: cidades
        })
        this.setState({ isLoading: false })
        console.log(this.state.cidades)
      });
    })
    .catch(err => {
      console.log('Error getting documents', err);
    });
  }

  onChange = (e) => {
    e = parseInt(e);
    this.setState({
      cidadeSelecionada: e,
      prazoSelecionado: this.state.cidades[e].prazo
    })
  }

  render() {
    return (
      <div id="onde-entregamos" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Onde Entregamos</h2>
            <p>
              Selecione o local para ver o prazo
            </p>
            {this.state.cidades ? 
            <Select
                size="large"
                showSearch
                style={{ width: 250 }}
                placeholder="Cidades"
                optionFilterProp="children"
                onChange={this.onChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.cidades.map((item, i) => {
                  return <Option value={i} key={i}>{item.cidade}</Option>;
                })}
              </Select> :
               <Select
               showSearch
               style={{ width: 150 }}
               placeholder="Cidades"
               optionFilterProp="children"
               onChange={this.onChange}
               onBlur={this.onBlur}
               onSearch={this.onSearch}
               filterOption={(input, option) =>
                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
               }
             >
               <Option value={0} key={0}>{"Cidades"}</Option>
             </Select> 
              }

              {this.state.cidadeSelecionada != null ? <div className="site-card-border-less-wrapper">
                <div>
                  <Text mark>{`O prazo de entrega é de ${ this.state.prazoSelecionado } dias.`}</Text>
                </div>
              </div>: <div></div>}
          </div>
        </div>
      </div>
    );
  }
}

export default OndeEntregamos;
