import React, { Component } from 'react';
import Navigation from '../navigation';
import Header from '../header';
import NossosServicos from '../NossosServicos';
import QuemSomos from '../QuemSomos';
import OndeEntregamos from '../OndeEntregamos';
// import Gallery from './components/gallery';
// import Testimonials from './components/testimonials';
// import Team from './components/Team';
import FaleConosco from '../FaleConosco';
import JsonData from '../../data/data.json';

export class Home extends Component {
  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    this.setState({landingPageData : JsonData})
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <div>
        <Navigation />
        <Header data={this.state.landingPageData.Header} />
        <QuemSomos data={this.state.landingPageData.QuemSomos} />
        <OndeEntregamos data={this.state.landingPageData.OndeEntregamos} />
        <NossosServicos data={this.state.landingPageData.NossosServicos} />
        {/* <Gallery /> */}
        {/* <Testimonials data={this.state.landingPageData.Testimonials} /> */}
        {/* <Team data={this.state.landingPageData.Team} /> */}
        <FaleConosco data={this.state.landingPageData.FaleConosco} />
      </div>
    )
  }
}

export default Home;
