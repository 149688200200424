import React, { Component } from 'react';
import { 
  Form,
  Input, 
  InputNumber, 
  Space, 
  Typography, 
  Select, 
  Button, 
  Popconfirm, 
  message,  
} from 'antd';
import { SaveOutlined, EditOutlined, DeleteOutlined, LogoutOutlined } from '@ant-design/icons';
import app from '../../base';


const { Title } = Typography;

const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const onFinish = values => {
  console.log('Success:', values);
};

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};

export class Cadastro extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      disabled: true,
      cidadeSelecionada: null,
      prazoSelecionado: null,
      cadastroCidade: null,
      cadastroPrazo: null,
      cidades: [
        
      ]
    }
  }

  componentDidMount(){
    let cidades = []

    app.firestore().collection('cidades').get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        let objeto = {
          id: doc.id,
          cidade: doc.data().cidade,
          prazo: doc.data().prazo
        }
        cidades.push(objeto)
        this.setState({
          cidades: cidades
        })
        console.log(this.state.cidades)
      });
    })
    .catch(err => {
      console.log('Error getting documents', err);
    });
  }

  // *--------------* GET/UPDATE/DELETE *--------------*

  // Update prazo
  update = () => {
    if(this.state.cidadeSelecionada != null){
      this.setState({
        disabled: !this.state.disabled,
      });
    }
    if(!this.state.disabled){
      let cidadesCopy = this.state.cidades
      cidadesCopy[this.state.cidadeSelecionada].prazo = this.state.prazoSelecionado

      this.setState({
        cidades: cidadesCopy
      })
      app.firestore().collection('cidades').doc(this.state.cidades[this.state.cidadeSelecionada].id).update({
        prazo: this.state.prazoSelecionado
      });
      message.success('Prazo atualizado com sucesso.');
    }
  };
  // Fim update

  // Funções de exclusão
  delete = (e) => {
    app.firestore().collection('cidades').doc(this.state.cidades[this.state.cidadeSelecionada].id).delete();

    let cidadesCopy = this.state.cidades
    delete cidadesCopy[this.state.cidadeSelecionada];

    cidadesCopy = cidadesCopy.filter(item => item);

    this.setState({
      cidades: cidadesCopy
    })
  }
  
  confirm = (e) => {
    this.delete(e);
    message.success('Exclusão realizada com sucesso.');
  }

  cancel = (e) => {
    console.log(e);
  }
  // Fim funções de exclusão

  // Select de cidades
  onChange = (e) => {
    e = parseInt(e);
    this.setState({
      cidadeSelecionada: e,
      disabled: true,
      prazoSelecionado: this.state.cidades[e].prazo
    })
  }

  setPrazoSelecionado = (value) => {
    this.setState({
      prazoSelecionado: value
    })
  }
// *--------------* FIM GET/UPDATE/DELETE *--------------*

// *---------------------* CREATE *---------------------*

create = () => {
  if(this.state.cadastroCidade && this.state.cadastroPrazo){
    app.firestore().collection('cidades').add({
      cidade: this.state.cadastroCidade.toUpperCase(),
      prazo: this.state.cadastroPrazo
    })
    message.success('Cidade registrada com sucesso.');
  }else{
    message.error("Os campos devem ser preenchidos.");
  }
}

onChangeCreateCidade = (e) => {
  this.setState({
    cadastroCidade: e.target.value
  })
}

onChangeCreatePrazo = (value) => {
  this.setState({
    cadastroPrazo: value
  })
}

  render(){
    return(
      <div className="default-form">
        <div className="logout">
          <a  onClick={() => app.auth().signOut()}>
            <React.Fragment>
              <LogoutOutlined /> Sair
            </React.Fragment>
          </a>
        </div>
        <Space direction="vertical">
          <Title level={3}>Cidades e Prazos</Title>
          <Typography.Paragraph>Selecione a cidade para ver seu prazo:</Typography.Paragraph>
          <Input.Group compact>
            <Space direction="vertical">
              <Space direction="horizontal">
              {this.state.cidades ? 
                <Select
                showSearch
                style={{ width: 150 }}
                placeholder="Cidades"
                optionFilterProp="children"
                onChange={this.onChange}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.cidades.map((item, i) => {
                  return <Option value={i} key={item.id}>{item.cidade}</Option>;
                })}
                </Select> : 
                <Select
                  showSearch
                  style={{ width: 150 }}
                  placeholder="Cidades"
                  optionFilterProp="children"
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={0} key={0}>{"Cidades"}</Option>
                </Select> 
              }
              

              {' '}
              <InputNumber
                step={1} 
                min={1} 
                max={1000} 
                onChange={this.setPrazoSelecionado} 
                disabled={this.state.disabled} 
                value={this.state.cidadeSelecionada != null ? this.state.prazoSelecionado : 1}
              />
              </Space>
              {' '}
              <Button block onClick={this.update} type="primary" disabled={this.state.cidadeSelecionada != null ? false : true}>
                {
                  !this.state.disabled ? 
                  (
                  <React.Fragment>
                    <SaveOutlined /> {'Salvar'} 
                  </React.Fragment>
                  ) : 
                  <React.Fragment>
                    <EditOutlined /> {'Editar'}
                  </React.Fragment>
                }
              </Button>
              {' '}
              {/* Só renderiza o botão de exclusão se houver cidade selecionada */}
              {this.state.cidadeSelecionada != null ? 
              (<Popconfirm
                title="Tem certeza que deseja excluir?"
                onConfirm={this.confirm}
                onCancel={this.cancel}
                okText="Sim"
                cancelText="Não"
              >
                <Button block type="primary" danger disabled={this.state.cidadeSelecionada != null ? false : true}>
                  <React.Fragment>
                    <DeleteOutlined /> {'Excluir'} 
                  </React.Fragment>
                </Button>
              </Popconfirm>) : (<div></div>)}
            </Space>
          </Input.Group>

          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Space direction="vertical">
              <Title level={3}>Cadastrar cidade e Prazo:</Title>
              <Form.Item
                label="Cidade"
                name="cidade"
                rules={[{ required: true, message: 'Insira o nome da cidade!' }]}
              >
                <Input
                  required
                  placeholder="Insira o nome da cidade" 
                  onChange={this.onChangeCreateCidade}
                />
              </Form.Item>
              <Form.Item
                label="Prazo"
                name="prazo"
                rules={[{ required: true, message: 'Insira o prazo de entrega!' }]}
              >
                <InputNumber
                  step={1} 
                  min={1} 
                  max={1000} 
                  required
                  placeholder="Prazo"
                  onChange={this.onChangeCreatePrazo}
                />
              </Form.Item>
              <Form.Item
                label="Prazo"
                name="prazo"
                rules={[{ required: true, message: 'Insira o prazo de entrega!' }]}
              >
                <React.Fragment>
                  <Button 
                    block
                    type="primary"
                    htmlType="submit"
                    onClick={this.create}
                  >
                    <SaveOutlined /> {'Salvar'}
                  </Button>
                </React.Fragment>
              </Form.Item>
            </Space>
          </Form>        
        </Space>
      </div>
    );
  }
}

export default Cadastro