import React, { useCallback, useContext } from 'react';
import { withRouter, Redirect } from 'react-router';
import app from '../base';
import { AuthContext } from './Auth';
import { Form, Input, Button, Typography } from 'antd';
import 'antd/dist/antd.css';

const { Title } = Typography;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const Login = ({ history }) => {
  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        history.push("/cadastro");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/cadastro" />;
  }

  return (
    <div className="default-form">
      <div className="login-wrapper">
        <div className='login-title'>
          <Title level={3}>Login - Action Express</Title>
        </div>
        <form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onSubmit={handleLogin}
        >
          <Form.Item
            label="Email"
            name="email"
            type="email"
            rules={[{ required: true, message: 'Insira seu email!' }]}
          >
            <Input/>
          </Form.Item>

          <Form.Item
            label="Senha"
            name="password"
            rules={[{ required: true, message: 'Insira sua senha!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" block> 
              Entrar
            </Button>
          </Form.Item>
        </form>
      </div>
    </div>
  );
};

export default withRouter(Login);