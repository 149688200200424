import React, { Component } from "react";
import { Modal, Input, Card } from 'antd';
import 'antd/dist/antd.css';
import getEntrega from '../http';

export class Header extends Component {
  state = { 
    visible: false,
    codigo: '',
    status: '',
    titulo: ''
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = () => {
    let entrega = getEntrega(this.state.codigo)
    this.setState({
      titulo: entrega.codigo,
      status: entrega.status
    });
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  }

  handleInputChange = (e) => {
    this.setState({
      codigo: e.target.value
    })
  }

  render() {
    return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    {this.props.data ? this.props.data.title : "Loading"}
                  </h1>
                  <a
                    className="btn btn-custom btn-lg page-scroll"
                    onClick={this.showModal}
                  >
                    Localizar entrega
                  </a>
                  <Modal
                    closable={false}
                    title="Localizar entrega"
                    visible={this.state.visible}
                    // onOk={this.handleOk}
                    onCancel={this.handleCancel}
                  >
                    <Input 
                      placeholder="Ex: 123456" 
                      addonBefore="Insira o código"
                      onChange={this.handleInputChange}
                    />
                    <div className={this.state.titulo === '' ? 'hidden' : 'site-card-border-less-wrapper'}>
                      <Card title={"Entrega #" + this.state.titulo} bordered={false} style={{ width: 300 }}>
                        <p>Status: { this.state.status }</p>
                      </Card>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
