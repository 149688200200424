import React, { Component } from 'react'

export class QuemSomos extends Component {
  render() {
    return (
        <div id="quem-somos">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <img src="img/quem-somos.png" className="img-responsive" alt=""/>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="quem-somos-text">
                <h2>Quem somos</h2>
                <p>{this.props.data ? this.props.data.paragraph : 'Carregando...'}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <h3>Nossos Serviços</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {this.props.data ? this.props.data.Why.map((d, i) => <li  key={`${d}-${i}`}>{d}</li>) : 'Carregando'}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {this.props.data ? this.props.data.Why2.map((d, i) => <li  key={`${d}-${i}`}> {d}</li>) : 'Carregando'}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <h3>Nossa Missão</h3>
              <p>{this.props.data ? this.props.data.missao : 'Carregando...'}</p>
              <h3>Nossa Visão</h3>
              <p>{this.props.data ? this.props.data.visao : 'Carregando...'}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default QuemSomos
