import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Home } from './components/pages/Home';
import  Login  from './components/Login';
import  SignUp  from './components/SignUp';
import { Cadastro } from './components/pages/Cadastro';
import { AuthProvider } from './components/Auth';
import PrivateRoute from './PrivateRoute';

export class App extends Component {
  render() {
    return (
      <AuthProvider>
        <Router>
          <div>
            <Route exact path='/' component={Home} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/registrar' component={SignUp} />
            <PrivateRoute exact path='/cadastro' component={Cadastro} />
          </div>
        </Router>
      </AuthProvider>
    )
  }
}

export default App;
