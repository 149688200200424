import React, { Component } from "react";

export class FaleConosco extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      Subject: '',
      Email: '',
      Body: ''   
    }
  }

  updateSubject = (e) => {
    this.setState({
      Subject: e.target.value
    })
  }

  updateEmail = (e) => {
    this.setState({
      Email: e.target.value
    })
  }

  updateBody = (e) => {
    this.setState({
      Body: e.target.value
    })
  }
  

  render() {
    return (
      <div>
        <div id="fale-conosco">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Fale conosco</h2>
                  <p>
                    Preencha o formulário abaixo para nos mandar um email.
                    Nós responderemos o mais rápido possível.
                  </p>
                </div>
                <form name="sentMessage" id="contactForm" noValidate>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          onChange = {this.updateSubject}
                          type="text"
                          id="subject"
                          className="form-control"
                          placeholder="Assunto"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          onChange = {this.updateEmail}
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      onChange = {this.updateBody}
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Mensagem"
                      required
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <a className="btn btn-custom btn-lg" href={`mailto:contato@actionexpress.com?subject=${this.state.Subject}&body=${this.state.Body}`}>
                    Enviar
                  </a>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 fale-conosco-info">
              <div className="fale-conosco-item">
                
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Endereço
                  </span>
                  {this.props.data ? this.props.data.address : "loading"}
                </p>
              </div>
              <div className="fale-conosco-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Telefone/Whatsapp
                  </span>{" "}
                  {this.props.data ? this.props.data.phone : "loading"}
                </p>
              </div>
              <div className="fale-conosco-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {this.props.data ? this.props.data.email : "loading"}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a
                        href={"#"}
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={"https://www.instagram.com/actionexpressoficial/"} target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2022 Action Express. Desenvolvido por{" "}
              <a href="https://www.linkedin.com/in/geovanebpjr/" rel="nofollow">
                Geovane Júnior
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default FaleConosco;
